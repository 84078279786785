
import Home from './Home';
function App() {
  return (
    <div className="overflow-y-auto h-screen  bg-[#0d1224]" style={{ backgroundImage: "url('../assets/section.svg')" }}>
      <Home />
    </div>
  );
}

export default App;
